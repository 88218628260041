import Portal from './portal';
import React from 'react';

import * as styles from './modal.module.css'

export default class Modal extends React.Component {
  /*
  static propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    subTitle: PropTypes.string,
    title: PropTypes.string,
    small: PropTypes.bool
  };
  */

  constructor (...args) {
    super(...args)
    this.keyDown = this.keyDown.bind(this)
  }


  componentDidMount () {
    const {onClose} = this.props;
    if (onClose) {
      this.keyDownBind = this.keyDown;
      document.addEventListener('keydown', this.keyDownBind);
    }
  }

  componentWillUnmount () {
    this.keyDownBind && document.removeEventListener('keydown', this.keyDownBind);
  }

  keyDown (evt) {
    const event = evt || window.event;

    // Check if escape key pressed (code: 27)
    const {onClose} = this.props;
    if (event.keyCode === 27 && onClose) {
      onClose();
    }
  }

  onClose () {
    const {onClose} = this.props;
    onClose && onClose();
  }

  render () {
    const {children, rootStyles, backgroundStyles, wrapperStyles} = this.props;

    return (
      <Portal>
        <div className={`${styles.modalRoot} ${rootStyles}`}>
          <div className={`${styles.modalBackground} ${backgroundStyles}`} onClick={this.onClose.bind(this)} />
          <div className={`${styles.modalWrapper} ${wrapperStyles}`}>
            {children}
          </div>
        </div>
      </Portal>
    );
  }
}

