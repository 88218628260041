import React from 'react'
import Gallery from './gallery'

import './gallery.css'

export default class GalleryComponent extends React.Component {
  render () {
    const { items } = this.props
    return (
      <Gallery photos={items} />
    )
  }
};
