import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Gallery from '../components/gallery'

const GalleryPage = ({ data }) => (
  <Layout>
    <Helmet title="See our Gallery of pools" />
    <section className="container">
      <h1 className="text-center">We take pool renovation seriously</h1>
      <h2 className="text-center">Have a look look through our gallery</h2>
      <Gallery items={data.allMarkdownRemark.edges} />
    </section>
  </Layout>
)

export default GalleryPage

/* eslint no-undef: 'off' */
export const pageQuery = graphql`
  query GalleryQuery {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "/gallery/" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image
            width
            height
            url
          }
        }
      }
    }
  }
`
