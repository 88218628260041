import React from 'react'
import ReactDOM from 'react-dom'
import Modal from '../modal'
import Link from 'gatsby-link'
import CrossIcon from '../cross-icon'

export default class Gallery extends React.Component {
  /*
  static propTypes = {
    photos: React.PropTypes.arrayOf(
      React.PropTypes.shape({
        image: React.PropTypes.string.isRequired,
        width: React.PropTypes.number.isRequired,
        height: React.PropTypes.number.isRequired
      })
    ).isRequired
  }
  */

  constructor (props) {
    super(props)
    this.state = {
      containerWidth: 0,
      modal: false
    }
    this.handleResize = this.handleResize.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount () {
    this.setState({containerWidth: Math.floor(ReactDOM.findDOMNode(this).clientWidth)})
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate () {
    if (ReactDOM.findDOMNode(this).clientWidth !== this.state.containerWidth) {
      this.setState({containerWidth: Math.floor(ReactDOM.findDOMNode(this).clientWidth)})
    }
  }

  handleResize (e) {
    this.setState({containerWidth: Math.floor(ReactDOM.findDOMNode(this).clientWidth)})
  }

  render () {
    var rowLimit = 1
    var photoPreviewNodes = []
    var photos = this.props.photos
    if (this.state.containerWidth >= 480) {
      rowLimit = 2
    }
    if (this.state.containerWidth >= 1024) {
      rowLimit = 3
    }
    var contWidth = this.state.containerWidth - (rowLimit * 4) /* 4px for margin around each image */
    contWidth = Math.floor(contWidth) // add some padding to prevent layout prob

    for (var i = 0; i < photos.length; i += rowLimit) {
      // loop thru each set of rowLimit num
      // eg. if rowLimit is 3 it will  loop thru 0,1,2, then 3,4,5 to perform calculations for the particular set
      var totalAr = 0
      var commonHeight = 0

      for (var j = i; j < i + rowLimit; j++) {
        if (j === photos.length) {
          break
        }
        totalAr += photos[j].node.frontmatter.width / photos[j].node.frontmatter.height
      }
      commonHeight = contWidth / totalAr
      // run thru the same set of items again to give the common height
      for (var k = i; k < i + rowLimit; k++) {
        if (k === photos.length) {
          break
        }
        photoPreviewNodes.push(
          <div key={k} style={style} onClick={this.openModal.bind(this, k)}>
            <a href='#' className={k}>
              <img
                src={photos[k].node.frontmatter.image}
                style={{display: 'block', border: 0}}
                height={commonHeight}
                width={commonHeight * (photos[k].node.frontmatter.width / photos[k].node.frontmatter.height)}
                alt=''
              />
            </a>
          </div>
        )
      }
    }

    var modal
    if (this.state.modal !== false) {
      var photo = photos[this.state.modal].node
      modal = (
        <Modal onClose={this.closeModal} wrapperStyles='galleryWrapper'>
          <CrossIcon onClick={this.closeModal} className='galleryCross' />
          <img className='galleryImg' src={photo.frontmatter.image} alt='' />
          <div className='galleryDescription'>
            {photo.frontmatter.description}
            {/* photo.frontmatter.url &&
              <Link to={photo.frontmatter.url} className='galleryViewProject'>View Project</Link>
            */}
          </div>
        </Modal>
        )
    }

    return (
      <div id='Gallery' className='clearfix'>
        {photoPreviewNodes}
        {modal}
      </div>
    )
  }

  openModal (key) {
    this.setState({
      modal: key
    })
  }

  closeModal () {
    this.setState({
      modal: false
    })
  }
}

// Gallery image style
const style = {
  display: 'block',
  margin: 2,
  float: 'left'
}
